@import "../../tokens/colors";
@import "../../tokens/spacings";
@import "../../tokens/typos";

.abouts {
  padding: 50px 0;
  width: 100%;
  height: 100%;
  background-color: $color-claro;

  &__copy {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 500px;
    background-image: url("../../../images/static/about__copy__background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    p {
      width: 100%;
      max-width: 90%;
      text-align: center;
      font-weight: $black;
      @include font-size(20px);

      br {
        display: none;
      }

      span {
        width: 100%;
        font-weight: $light;
        text-align: right !important;
        @include font-size(18px);
      }
    }

    @include media(desktop-s) {
      min-height: 500px;

      p {
        max-width: 80%;
        @include font-size(40px);

        span {
          @include font-size(18px);
        }
      }
    }

    @include media(desktop-xl) {
      min-height: 800px;

      p {
        max-width: 60%;
        @include font-size(40px);

        br {
          display: block;
        }

        span {
          @include font-size(18px);
        }
      }
    }
  }

  &__slide {
    padding: 50px 0;
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 100vw;
    min-height: 300px;
    color: $color-claro;
    user-select: none;
    background-color: $color-bg-oscuro;

    &__rss {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      max-width: 100vw;
      overflow: hidden;
      transform: translate(-50%, -50%);
    }

    /*remove p*/
    p {
      width: auto;
      height: auto;
      margin: $space auto;
      white-space: nowrap;
      font-weight: $black;
      text-transform: uppercase;
      -webkit-text-stroke: 1px $color-claro;
      -webkit-text-fill-color: transparent;
      @include font-size(40px);

      span {
        display: inline-block;
      }
    }

    .left {
      animation: scrolling-left1 50s linear infinite;
    }

    .left2 {
      animation: scrolling-left2 50s linear infinite;
      animation-delay: 25s;
    }

    .right {
      animation: scrolling-right1 50s linear infinite;
    }

    .right2 {
      animation: scrolling-right2 50s linear infinite;
      animation-delay: 25s;
    }
  }

  &__container {
    padding: 50px 0 0;
    margin: 0 auto;
    width: 90%;
    height: 100%;
    user-select: none;
    @include display-grid;
    @include grid-template-columns(auto);

    h2 {
      margin-bottom: $space_x2;
      font-weight: $black;
      @include font-size(40px);
      line-height: 40px;
    }

    &__text {
      h3 {
        margin: $space_x3 0 0;
        @include font-size(20px);
      }

      p {
        max-width: 850px;
        margin-bottom: $space_x2;
        font-weight: $light;
        @include font-size(16px);
      }

      ul {
        padding: 0;
        width: 100%;
        max-width: 500px;
        list-style: none;
        @include display-grid;
        @include grid-template-columns(auto);

        &.list {
          display: block;
        }

        li {
          padding: $space 0;
          text-align: left;
          @include font-size(15px);

          small {
            @include font-size(10px);
          }
        }
      }
    }
  }

  @include media(tablet) {
    padding: 100px 0 250px;

    &__container {
      margin: 0 auto;
      width: 90%;
      @include grid-template-columns(auto 50%);

      h2 {
        grid-row: 1;
        margin-bottom: $space_x4;
        @include font-size(50px);
        line-height: 50px;
      }

      &__text {
        grid-column: 2;
        grid-row: 2;
        p {
          max-width: 800px;
          margin-bottom: $space_x3;
          @include font-size(18px);
        }

        ul {
          max-width: 500px;

          li {
            padding: $space 0;
            @include font-size(16px);
          }
        }
      }
    }
  }

  @include media(desktop-s) {
    padding: 150px 0;

    &__slide {
      padding: 80px 0;
      min-height: 600px;

      p {
        margin: $space_x3 auto;
        @include font-size(60px);
      }
    }

    &__container {
      padding: 150px 0;
      margin: 0 auto;
      width: 80%;

      h2 {
        position: sticky;
        top: 150px;
        width: 100%;
        height: 100%;
        margin-bottom: $space_x4;
        @include font-size(50px);
        line-height: 50px;
      }

      p {
        max-width: none;
        margin-bottom: $space_x3;
        @include font-size(18px);
      }

      ul {
        max-width: 500px;
        @include grid-template-columns(repeat(2, 1fr));

        li {
          padding: $space 0;
          @include font-size(16px);
        }
      }
    }
  }

  @include media(desktop-m) {
    &__container {
      margin: 0 auto;
      width: 80%;

      h2 {
        margin-bottom: $space_x4;
        @include font-size(70px);
        line-height: 70px;
      }

      h3 {
        margin: 60px 0 $space_x2;
        @include font-size(30px);
      }

      p {
        max-width: 850px;
        margin-bottom: $space_x3;
        @include font-size(20px);
      }

      ul {
        max-width: 800px;

        li {
          padding: $space 0;
          @include font-size(18px);
        }
      }
    }
  }

  @include media(desktop-xl) {
    &__container {
      margin: 0 auto;
      width: 70%;
    }
  }
}
