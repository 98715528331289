@import "../../tokens/colors";
@import "../../tokens/spacings";
@import "../../tokens/typos";

.generalLoader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $color-bg-claro;

  img {
    width: 100%;
    max-width: 400px;
    object-fit: cover;
    animation: pulse 2s ease-in-out 0s infinite both;
  }
}
