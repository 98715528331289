@import "../../tokens/colors";
@import "../../tokens/spacings";
@import "../../tokens/typos";

.agencies {
  padding: 30px 0;
  width: 100%;
  height: 100%;
  background-color: $color-bg-oscuro;

  &__header {
    padding: $space_x2 0;

    &__container {
      margin: 0 auto;
      width: 90%;
      height: 100%;
      color: $color-claro;

      h2 {
        font-weight: $black;
        @include font-size(30px);
      }

      p {
        @include font-size(16px);
      }
    }
  }

  &__container {
    margin: 0 auto;
    width: 90%;
    height: 100%;
    @include display-grid;
    @include grid-template-columns(auto);

    img {
      margin: $space_x5 auto;
      width: 180px;
      object-fit: cover;
      image-rendering: auto;
    }

    .space {
      display: none;
    }
  }

  @include media(desktop-s) {
    padding: 100px 0;
    &__header {
      padding: $space_x2 0;

      &__container {
        margin: 0 auto;
        width: 80%;

        h2 {
          @include font-size(60px);
        }

        p {
          @include font-size(18px);
        }
      }
    }

    &__container {
      margin: 0 auto;
      width: 80%;
      @include grid-template-columns(33% 33% 33%);

      img {
        margin: $space_x5 auto;
        width: 180px;
      }

      .space {
        display: block;
        opacity: 0;
      }
    }
  }

  @include media(desktop-xl) {
    &__header {
      padding: $space_x5 0;

      &__container {
        margin: 0 auto;
        width: 70%;

        h2 {
          @include font-size(80px);
        }

        p {
          @include font-size(20px);
        }
      }
    }

    &__container {
      margin: 0 auto;
      width: 70%;
      @include grid-template-columns(33% 33% 33%);

      img {
        margin: $space_x5 auto;
        width: 200px;
      }
    }
  }
}
