.menu {
  position: fixed;
  z-index: 98;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 100vw;
  height: 100vh;
  transition: all 500ms ease-out;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: $color-bg-claro !important;
  transition: all 0.5s ease-in-out;
  animation: header-reveal 1s ease-in-out 0.5s both;
  background-color: get-opacity($color-claro, 1);

  &.opened {
    height: 100vh;
    transition: all 1s ease-in;
  }

  &.closed {
    height: 0%;
  }

  &__container {
    padding: 0px $space_x2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    max-width: 80%;
    transition: all 0.5s ease-in-out 0.1s;

    &__button {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      transition: all 0.3s ease-in-out;

      p {
        margin-right: $space;
        text-transform: uppercase;
        font-weight: $bolder;
        color: $color-secundario;
        cursor: default;
        transition: all 0.3s ease-in-out;
        @include font-size(16px);
      }

      button {
        padding: 0;
        display: flex;
        font-weight: $black;
        border: none;
        outline: none;
        cursor: pointer;
        background-color: transparent;
      }

      svg {
        width: 40px;
      }

      .line {
        fill: none;
        stroke: $color-secundario;
        stroke-width: 6;
        transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1),
          stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
      }

      .line1 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
      }

      .line2 {
        stroke-dasharray: 60 60;
        stroke-width: 6;
      }

      .line3 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
      }

      .opened .line1 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
      }

      .opened .line2 {
        stroke-dasharray: 1 60;
        stroke-dashoffset: -30;
        stroke-width: 6;
      }

      .opened .line3 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
      }

      &:hover {
        p {
          color: $color-primario;
        }

        .line {
          stroke: $color-primario;
        }
      }
    }
  }

  &__content {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: 100%;
    @include display-grid;
    @include grid-template-columns(100%);

    &__links {
      padding: $space 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      grid-column: 1;
      grid-row: 1;
      width: 100%;
      height: 100%;

      a {
        padding: $space_half 0;
        font-family: $Fuente1;
        font-weight: $black;
        text-decoration: none;
        color: $color-oscuro;
        transition: all 0.3s ease-in-out;
        @include font-size(50px);

        &:hover {
          color: $color-primario;
        }
      }

      button {
        margin: $space_x5 $space_x2 0;
        outline: none;
        border: none;
        border-radius: 5px;
        font-family: $Fuente1;
        font-weight: $bolder;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        color: $color-oscuro;

        &[data-active="true"] {
          outline: 2px solid $color-oscuro !important;

          &:hover {
            color: $color-oscuro;
            background-color: transparent;
          }
        }

        &:hover {
          background-color: $color-oscuro;
          color: $color-bg-claro;
        }
      }
    }
  }

  @media only screen and (orientation: landscape) {
    overflow-y: auto;

    &__container {
      padding: 18px $space_x2;
    }
  }

  @include media(tablet) {
    &__content {
      &__links {
        padding: $space 0;

        a {
          @include font-size(30px);
        }
      }
    }
  }

  @include media(desktop-s) {
    z-index: 99;
    background-color: get-opacity($color-claro, 0.92);

    &__container {
      padding: 34px $space_x2;
      max-width: 100%;

      &.isVisible {
        padding: 24px $space_x2;
      }
    }

    &__content {
      max-width: 90%;
      @include grid-template-columns(50% 50%);

      &__links {
        padding: $space 0;
        align-items: flex-start;
        grid-column: 1;
        grid-row: 2;

        a {
          padding: 0;
          @include font-size(35px);
        }
      }
    }
  }

  @include media(desktop-m) {
    &__container {
      padding: 42px $space_x2;
      max-width: 90%;
    }

    &__content {
      max-width: 90%;

      &__links {
        padding: $space 0;

        a {
          @include font-size(40px);
        }
      }
    }
  }

  @include media(desktop-ml) {
    &__container {
      padding: 52px $space_x2;
      max-width: 80%;
    }

    &__content {
      max-width: 80%;

      &__links {
        padding: $space 0;

        a {
          @include font-size(42px);
        }
      }
    }
  }

  @include media(desktop-xxl) {
    &__container {
      padding: 53px $space_x2;
      max-width: 80%;
    }

    &__content {
      max-width: 70%;

      &__links {
        padding: $space 0;

        a {
          @include font-size(40px);
        }
      }
    }
  }
}
