@import "../../tokens/colors";
@import "../../tokens/spacings";
@import "../../tokens/typos";

.modalworks {
  // display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  user-select: none;
  animation: fade-in 1s ease-in-out 0.5s both;
  background-color: get-opacity($color-claro, 1);

  &__back {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 99;
    width: 70px;
    height: 70px;
    cursor: pointer;
    border: none;
    border-radius: 100%;
    outline: none !important;
    color: $color-oscuro;
    background-color: $color-claro;
    animation: fade-in 1s ease-in-out 0.8s both;
    @include font-size(40px);
  }

  &__header {
    position: relative;
    width: 100%;
    height: 100vh;
  }

  &__image {
    position: relative;
    margin: $space_x3 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease-in-out;

    img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
      image-rendering: optimizeQuality;
      transition: all 0.5s ease-in-out;
      aspect-ratio: 16 / 9;
    }
  }

  &__title {
    padding-bottom: $space_x5;
    position: absolute;
    bottom: 0vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 200px;
    text-align: center;
    animation: fade-in 1s ease-in-out 3s both;
    background: $color-oscuro;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 5, 0.0) 0%,
      rgba(0, 0, 5, 0.4) 30%,
      rgba(0, 0, 5, 0.8) 100%
    );

    h2 {
      width: 100%;
      text-align: center;
      animation: reveal-text-up 1s ease-in-out 4s both;
      color: $color-claro;
      @include font-size(75px);
    }

    p {
      animation: reveal-text-up 1s ease-in-out 4s both;
      color: $color-claro;
      @include font-size(20px);
    }
  }

  &__line {
    position: absolute;
    bottom: 0;
    width: 0px;
    height: 50px;
    border-left: 2px solid $color-claro;
    animation: reveal-text-up 1s ease-in-out 4.5s both;
  }

  &__container {
    padding: $space_x5 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__rows {
    padding: $space_x5 0;
    width: 100%;
    max-width: 60%;

    h3 {
      margin-bottom: $space;
      width: 100%;
      min-width: 350px;
      max-width: 60%;
      text-align: left;
      font-weight: $black;
      @include font-size(25px);
    }

    ul {
      width: 100%;
      max-width: 60%;
      text-transform: capitalize;
    }

    p {
      margin: 0;
      width: 100%;
      min-width: 350px;
      max-width: 60%;
      @include font-size(20px);
    }

    img {
      margin: $space_x2 0;
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    button, a {
      padding: $space $space_x2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: $space_x5;
      text-decoration: none;
      width: fit-content;
      font-family: $Fuente1;
      cursor: pointer;
      outline: none !important;
      border: none;
      color: $color-claro;
      background-color: $color-primario;

      svg{
        margin-left: $space;
      }
    }
  }

  @include media(desktop-s) {
    &__image {
      position: relative;
      top: 50%;
      left: 70%;
      z-index: -1;
      margin: 0;
      width: 610px;
      height: 500px;
      opacity: 1;
      transform: translate(-20%, -50%);
      transition: all 1s ease-in-out;
      animation-name: modal-container-movement, modal-container-size;
      animation-timing-function: ease-in-out, ease-in-out;
      animation-duration: 1s, 1.5s;
      animation-delay: 1.5s, 2.5s;
      animation-fill-mode: both, both;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        aspect-ratio: 16 / 9;
        transform: skew(0deg, 0deg) scale(1.1);
        clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 90%);
        transition: all 1s ease-in-out;
        image-rendering: optimizeQuality;
        overflow: hidden;
        animation: modal-container-movement-image 1s ease-in-out 1s both;
      }
    }
  }

  @include media(desktop-xl) {
    &__image {
      top: 41%;
      left: 70%;
      margin: 0;
      width: 710px;
      transform: translate(-20%, -50%);
    }
  }
}
