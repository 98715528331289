//  ███████╗███████╗██████╗..█████╗..██████╗██╗.█████╗.██████╗..██████╗.
//  ██╔════╝██╔════╝██╔══██╗██╔══██╗██╔════╝██║██╔══██╗██╔══██╗██╔═══██╗
//  █████╗..███████╗██████╔╝███████║██║.....██║███████║██║..██║██║...██║
//  ██╔══╝..╚════██║██╔═══╝.██╔══██║██║.....██║██╔══██║██║..██║██║...██║
//  ███████╗███████║██║.....██║..██║╚██████╗██║██║..██║██████╔╝╚██████╔╝
//  ╚══════╝╚══════╝╚═╝.....╚═╝..╚═╝.╚═════╝╚═╝╚═╝..╚═╝╚═════╝..╚═════╝.
//  ....................................................................
//Unidades
$space:10px;
$space_half:5px;
$space_x2:20px;
$space_x3: 30px;
$space_x4: 40px;
$space_x5: 50px;