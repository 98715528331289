@import "../../tokens/colors";
@import "../../tokens/spacings";
@import "../../tokens/typos";

.main {
  padding: 200px 0 80px;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  min-height: 600px;
  user-select: none;

  &__hero {
    margin: 0 auto;
    width: 90%;
    transition: all 0.3s ease-in-out;

    span {
      font-weight: $black;
      transition: all 0.3s ease-in-out;
      animation: reveal-text-up 1s ease-in-out 0.4s both;
      color: get-opacity($color-oscuro, 1);
      @include font-size(18px);
    }

    h1 {
      font-weight: $black;
      transition: all 0.3s ease-in-out;
      animation: reveal-text-up 1s ease-in-out 0.5s both;
      color: $color-oscuro;
      @include font-size(50px);
      line-height: 50px;
    }

    p {
      font-weight: $black;
      transition: all 0.3s ease-in-out;
      animation: reveal-text-up 1s ease-in-out 0.8s both;
      color: get-opacity($color-oscuro, 0.7);
      @include font-size(20px);
    }

    &__description {
      width: 100%;
      p {
        padding-bottom: $space_x3;
        font-weight: $normal;
        transition: all 0.3s ease-in-out;

        animation: reveal-text-up 1s ease-in-out 1s both;
        color: get-opacity($color-oscuro, 1);
        @include font-size(18px);
      }

      a {
        @extend .callToActions;
      }
    }
  }

  @include media(tablet) {
    padding: 300px 0 150px;
    min-height: none;

    &__hero {
      margin: 0 auto;
      width: 80%;

      span {
        @include font-size(18px);
      }

      h1 {
        @include font-size(80px);
        line-height: 80px;
      }

      p {
        @include font-size(35px);
      }

      &__description {
        max-width: 650px;

        p {
          padding-bottom: $space;
          @include font-size(18px);
        }
      }
    }
  }

  @include media(desktop-s) {
    padding: 350px $space_x2 250px;
    min-height: 650px;

    &__hero {
      margin: 0 auto;
      width: 80%;

      span {
        @include font-size(18px);
      }

      h1 {
        @include font-size(90px);
        line-height: 90px;
      }

      p {
        @include font-size(40px);
      }

      &__description {
        max-width: 630px;

        p {
          padding-bottom: $space;
          @include font-size(18px);
        }
      }
    }
  }

  @include media(desktop-m) {
    min-height: 750px;

    &__hero {
      margin: 0 auto;
      width: 80%;

      span {
        @include font-size(20px);
      }

      h1 {
        @include font-size(100px);
        line-height: 90px;
      }

      p {
        @include font-size(50px);
      }

      &__description {
        max-width: 680px;

        p {
          padding-bottom: $space_x2;
          @include font-size(20px);
        }
      }
    }
  }

  @include media(desktop-xl) {
    min-height: 750px;

    &__hero {
      margin: 0 auto;
      width: 70%;
    }
  }
}
