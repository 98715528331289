
// Brand colors
$color-primario: #e6444e;
$color-secundario: #f371b4;

// Action colors
$color-variante: #241044;
$color-variante-dos:  #ff00ff ;

// Boolean colors
$color-negative: rgba(255, 0, 0, 0.8);
$color-positive: #04bf42;

// Background colors
$color-bg-claro: #F2F2F2;
$color-bg-oscuro: #060606;

//Basic colors
$color-claro: #ffffff;
$color-oscuro: #363636;