@import "./../tokens/colors";
@import "./../tokens/spacings";
@import "./../tokens/typos";

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: $color-bg-claro;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: $color-oscuro;
}

::-webkit-scrollbar-thumb:hover {
  background: $color-bg-oscuro;
}

body {
  font-family: $Fuente1;
  overflow-x: hidden !important;
  color: $color-oscuro;
  scroll-behavior: smooth;
  background-color: $color-bg-claro;
  // cursor: url("data:image/svg+xml,%3Csvg height='6' width='6' viewBox='0 0 8 8' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4' cy='4' fill='%23eee' r='4'/%3E%3C/svg%3E") 3 3, auto;
}
