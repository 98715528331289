@keyframes reveal-text-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scale-in {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-in-opacity {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

@keyframes scrolling-left1 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scrolling-left2 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-200%);
  }
}

@keyframes scrolling-right1 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes scrolling-right2 {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes modal-container-movement {
  0% {
    left: 70%;
    transform: translate(-20%, -50%);
  }
  50% {
    transform: translate(-50%, -50%);
  }
  100% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes modal-container-size {
  0% {
  }
  50% {
    width: 50vw;
    height: 80vh;
  }
  100% {
    width: 100vw;
    height: 100%;
  }
}

@keyframes modal-container-movement-image {
  0% {
    transform: skew(0deg, 0deg) scale(1);
    clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 90%);
  }
  50% {
    transform: skew(0deg, 0deg) scale(1.1);
  }
  100% {
    transform: skew(0deg, 0deg) scale(1);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }
}
