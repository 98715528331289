//  ███████╗.██████╗.███╗...██╗████████╗███████╗
//  ██╔════╝██╔═══██╗████╗..██║╚══██╔══╝██╔════╝
//  █████╗..██║...██║██╔██╗.██║...██║...███████╗
//  ██╔══╝..██║...██║██║╚██╗██║...██║...╚════██║
//  ██║.....╚██████╔╝██║.╚████║...██║...███████║
//  ╚═╝......╚═════╝.╚═╝..╚═══╝...╚═╝...╚══════╝
//  ............................................


$Fuente1: 'Poppins', system-ui;
$Fuente2: 'Oswald', system-ui;
$Fuente3: 'Yellowtail', system-ui;
//typos
$h1: 50px;
$h2: 30px;
$h3: 20px;
$p: 15px;
$small: 10px;


//Typo bolds

$black: 800;
$bolder: 700;
$medium: 600;
$normal: 500;
$light: 400;